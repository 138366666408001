<template>
	<header>
		<div id="logoDiv">
			<img src="../assets/logo 1.png" alt="Logo Prema" />
		</div>
		<div id="desktopMenu">
			<!-- Inserir aqui o menu Desktop -->
		</div>
	</header>
</template>

<script>
export default {

}
</script>

<style scoped>
header {
	background: #7329b8dd;
	height: 140px;
	display: flex;
	box-sizing: border-box;
	z-index: 2;
}

#logoDiv {
	flex: 1;
	display: flex;
}

#desktopMenu {
	display: none;
}

header img {
	height: 50px;
	margin: auto;
}

@media (min-width: 1024px) {
	#desktopMenu {
		display: flex;
		flex: 1;
	}
}
</style>
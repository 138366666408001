import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import cards from './cards'
import testmonials from './testmonials'

Vue.use(Vuex)

var timer = null

export default new Vuex.Store({
	plugins: [createPersistedState({
		storage: window.sessionStorage
	})],
	state: {
		baseURL: process.env.VUE_APP_BASE_URL,
		cards: cards,
		testmonials: testmonials,
		message: null,
		success: false,
		userInfos: {
			name: '',
			email: '',
			plate: '',
			phone: '',
			postcode: '',
			mileage: ''
		},
		messages: {
			"/cell_phone~pattern": "Celular inválido",
			"/confirmation_code~maxLength": "Token Inválido!",
			"/confirmation_code~minLength": "Token Inválido!",
			"/email~format": "Email inválido",
			"/mileage~maxLength": "Quilometragem inválida",
			"/mileage~minLength": "Quilometragem inválida",
			"/mileage~pattern": "Quilometragem inválida",
			"/name~maxLength": "O nome deve ter no máximo 50 caracteres",
			"/name~minLength": "O nome deve ter no mínimo 4 caracteres",
			"/plate~maxLength": "Placa inválida",
			"/plate~minLength": "Placa inválida",
			"/plate~pattern": "Placa inválida",
			"/postcode~pattern": "CEP inválido",
			"/quote_id~pattern": "Identificação da cotação inválida. Por favor, preencha seus dados novamente."
		}
	},
	mutations: {
		SET_MESSAGE(state, data) {
			state.message = data.success || data.error
			state.success = data.success ? true : false
		},
		SET_FORM_1(state, data) {
			state.userInfos.plate = data.plate
			state.userInfos.phone = data.phone
		},
		SET_FORM_2(state, data) {
			state.userInfos.name = data.name
			state.userInfos.email = data.email
		},
		SET_FORM_3(state, data) {
			state.userInfos.postcode = data.postcode
			state.userInfos.mileage = data.mileage
		}
	},
	actions: {
		buildMessage({state, dispatch}, data) {
			if (data.length >= 1) {
				dispatch('showMessage', {error: state.messages[data[0].dataPath + '~' + data[0].keyword] || data[0].message})
			}
			else {
				if (data.message)
					dispatch('showMessage', {error: data.message})
				else {
					dispatch('showMessage', {error: data.error})
				}
			}
		},
		showMessage({commit, dispatch}, data) {
			clearTimeout(timer)
			commit('SET_MESSAGE', data)

			if (data.success) {
				timer = setTimeout(() => {
					dispatch('hideMessage')
				}, 5000)
			}
		},
		hideMessage({commit}) {
			clearTimeout(timer)
			commit('SET_MESSAGE', '')
		},
		setForm1({commit}, data) {
			commit('SET_FORM_1', data)
		},
		setForm2({commit}, data) {
			commit('SET_FORM_2', data)
		},
		setForm3({commit}, data) {
			commit('SET_FORM_3', data)
		}
	}
})
<template>
	<div id="container">
		<Header />
		<section>
			<div id="formContainer">
				<form @submit.prevent="submit">
					<img src="../assets/dialog.png" alt="Imagem Diálogo" />
					<span id="currentStep">Passo 2 de 3</span>
					<h1>Perfeito!</h1>
					<p>
						<b>Antes de continuarmos, vamos nos conhecer um pouco melhor?</b>
						<br />Conta pra gente qual é o seu nome!
					</p>

					<label for="nameInput">
						<b>Nome</b>
						<span>*</span>
					</label>
					<input type="text" class="formInput" id="nameInput" placeholder="Conte pra gente o seu nome" v-model="name" autocomplete="off" />

					<label for="emailInput">
						<b>Email</b>
						<span>*</span>
					</label>
					<input type="text" class="formInput" id="emailInput" placeholder="O seu email também" v-model="email" autocomplete="off" />

					<button type="submit" class="greenBt">Enviar</button>
				</form>
			</div>
			<div id="imgContainer">
				<img src="../assets/step2img.png" alt="Ilustração Cartão de identificação" />
			</div>
		</section>
	</div>
</template>

<script>
import Header from '../components/Header'
export default {
	name: "Step2",
	data() {
		return {
			name: this.$store.state.userInfos.name,
			email: this.$store.state.userInfos.email
		}
	},
	components: {
		Header
	},
	mounted() {
		document.getElementById('nameInput').focus()
	},
	methods: {
		submit() {
			if (this.fieldHasError({name: this.name}))
				this.$store.dispatch('showMessage', {error: 'O nome deve ter entre 3 e 50 caracteres.'})//aqui
			else if (this.fieldHasError({email: this.email}))
				this.$store.dispatch('showMessage', {error: 'Email inválido.'})
			else {
				this.$store.dispatch('setForm2', {
					name: this.name,
					email: this.email
				})
					.then(() => {
						this.$router.push('/step3')
					})
			}
		}
	}

}
</script>

<style scoped>
#container {
	min-height: 100vh;
	background: url("../assets/pills.png");
	background-size: 100%;
	background-color: #ededed;
}

section {
	width: 100%;
	height: 100%;
	display: flex;
	padding: 50px 0;
}

#formContainer {
	flex: 1;
	display: flex;
	height: 100%;
	/* background-color: red; */
	justify-content: center;
	align-items: center;
}

form {
	width: 90%;
	max-width: 360px;
	background: #f8f8f8;
	padding: 40px 20px;
	box-sizing: border-box;
	border-radius: 0.5rem;
	box-shadow: 0 0 15px #0000004d;
	display: flex;
	flex-direction: column;
	color: #7d7d7d;
}

form img {
	width: 82px;
	margin-bottom: 10px;
}

#currentStep {
	font-size: 14px;
	margin: 0;
}

h1 {
	color: #70b99b;
	margin: 0;
	font-family: arlon-bold;
}

b {
	font-family: arlon-bold;
}

label span {
	color: #70b99b;
	font-family: arlon-bold;
}

button {
	width: 70%;
	margin: 40px auto 0;
}

#imgContainer {
	flex: 1;
	display: none;
	align-items: center;
	justify-content: flex-start;
}

#imgContainer img {
	width: 80%;
	max-width: 490px;
}

@media (min-width: 1024px) {
	#formContainer {
		justify-content: flex-end;
		padding-right: 20px;
	}

	#imgContainer {
		padding-left: 20px;
		display: flex;
	}
}
</style>
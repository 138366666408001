import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store.js'
import Home from '../views/Home.vue'
import Step1 from '../views/Step1.vue'
import Step2 from '../views/Step2.vue'
import Step3 from '../views/Step3.vue'
import AllStepsDone from '../views/AllStepsDone.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/step1',
		name: 'Step1',
		component: Step1
	},
	{
		path: '/step2',
		name: 'Step2',
		component: Step2
	},
	{
		path: '/step3',
		name: 'Step3',
		component: Step3
	},
	{
		path: '/allDone',
		name: 'AllStepsDone',
		component: AllStepsDone
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	store.dispatch('hideMessage')
	next()
})

export default router

import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './router/router'
import VueMask from 'v-mask'

Vue.use(VueMask)

const platePattern = new RegExp(/^([a-zA-Z]{3}[-][0-9]{1}[a-zA-Z]{1}[0-9]{2})|([a-zA-Z]{3}[-][0-9]{4})$/)
const phonePattern = new RegExp(/^\([1-9]{2}\) (9[1-9])[0-9]{3}-[0-9]{4}$/)
const postcodePattern = new RegExp(/^\d{2,3}[.]\d{3}[-]\d{3}$/)

Vue.mixin({
	methods: {
		fieldHasError(field) {
			console.log(field)
			switch (Object.keys(field)[0]) {
				case 'plate':
					if (!platePattern.test(field.plate))
						return true
					break
				case 'phone':
					if (!phonePattern.test(field.phone))
						return true
					break
				case 'name':
					if (field.name.length < 3 || field.name.length > 50)
						return true
					break
				case 'email':
					if (field.email.split('@').length < 2)
						return true
					var periodsArray = field.email.split('@')[1].split('.')
					if (periodsArray.length < 2)
						return true
					for (let i = 0; i < periodsArray.length; i++) {
						if (periodsArray[i].trim() == '')
							return true
					}
					break
				case 'postcode':
					if (!postcodePattern.test(field.postcode))
						return true
					break
			}
			return false
		}
	}
})

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

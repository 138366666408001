export default [
	{
		img: require("../assets/ele.png"),
		alt: "imagem de um homem",
		name: "Bruno Borraccia",
		job: "Cliente Premalover",
		address: "",
		testmonial: "Fiquei muito feliz com o atendimento desde o agendamento até o pós-venda. Agradeço muito pelo serviço e transparência, foi um serviço bastante diferenciado."
	},
	{
		img: require("../assets/ele.png"),
		alt: "imagem de um homem",
		name: "Guilherme Kudiess",
		job: "Cliente Premalover",
		address: "",
		testmonial: "A PremaCar foi uma baita descoberta. Pra mim que não tenho tempo de ficar levando o carro pra revisão, eles levam e trazem o carro, me mostram a vida útil de vários itens, como pneus, disco de freio… o vídeo da revisão colocando as peças novas e ainda foi bem mais barato do que na concessionária. Revisão agora só quero se for padrão Prema."
	},
	{
		img: require("../assets/ela.png"),
		alt: "imagem de uma mulher",
		name: "Laura del Mauro",
		job: "Cliente Premalover",
		address: "",
		testmonial: "Foi ótimo a experiência. Conseguiram uma peça original por ótimo valor e muito rápido. Fiquei bem satisfeita com o serviço. Atendimento excelente. Todos profissionais muito atenciosos e eficientes. Pretendo voltar quando necessário."
	},
	{
		img: require("../assets/ele.png"),
		alt: "imagem de um homem",
		name: "Douglas Schaidhauer",
		job: "Cliente Premalover",
		address: "",
		testmonial: "Excelente atendimento, fiquei surpreendido com a dedicação e a tecnologia dos serviços. Hoje em dia é difícil encontrar transparência e sinceridade e na Prema encontrei, onde eles realmente trocam o necessário e se não precisar, te avisam. Parabéns pela equipe!"
	}
]
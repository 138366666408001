<template>
	<div id="container">
		<Header />
		<section>
			<div id="formContainer">
				<form @submit.prevent="">
					<img src="../assets/done.png" alt="Imagem Sucesso" />
					<span id="currentStep">Pronto!</span>
					<h1>Está feito!</h1>
					<p>
						Obrigado, {{$store.state.userInfos.name}}! 
						Suas Informações já foram repassadas aos nossos consultores e em breve você receberá no seu 
						celular um link para acessar a cotação para a revisão ideal do seu veículo.
					</p>

					<button class="greenBt">Voltar ao site</button>
					<button class="greenBt">Tire suas dúvidas no nosso FAQ</button>
				</form>
			</div>
			<div id="imgContainer">
				<img src="../assets/allStepsDoneImg.png" alt="Ilustração mulher sentada" />
			</div>
		</section>
	</div>
</template>

<script>
import Header from '../components/Header'
export default {
	name: "AllStepsDone",
	components: {
		Header
	}
}
</script>

<style scoped>
#container {
	min-height: 100vh;
	background: url("../assets/pills.png");
	background-size: 100%;
	background-color: #ededed;
}

section {
	width: 100%;
	height: 100%;
	display: flex;
	padding: 50px 0;
}

#formContainer {
	flex: 1;
	display: flex;
	height: 100%;
	/* background-color: red; */
	justify-content: center;
	align-items: center;
}

form {
	width: 90%;
	max-width: 360px;
	background: #f8f8f8;
	padding: 40px 20px;
	box-sizing: border-box;
	border-radius: 0.5rem;
	box-shadow: 0 0 15px #0000004d;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	color: #7d7d7d;
}

form img {
	width: 82px;
	margin-bottom: 10px;
}

#currentStep {
	font-size: 14px;
	margin: 0;
}

h1 {
	color: #70b99b;
	margin: 0;
	font-family: arlon-bold;
}

p{
	margin-bottom: 20px;
}

label span {
	color: #70b99b;
	font-family: arlon-bold;
}

button {
	width: fit-content;
	margin: 20px auto 0;
}

#imgContainer {
	flex: 1;
	display: none;
	align-items: center;
	justify-content: flex-start;
}

#imgContainer img {
	width: 80%;
	max-width: 374px;
}

@media (min-width: 1024px) {
	#formContainer {
		justify-content: flex-end;
		padding-right: 20px;
	}

	#imgContainer {
		padding-left: 20px;
		display: flex;
	}
}
</style>
export default [
	{
		img: require("../assets/lp_card (1).png"),
		alt: "imagem",
		text: "Cotação on-line e grátis para comparar preços."
	},
	{
		img: require("../assets/lp_card (2).png"),
		alt: "imagem",
		text: "Receba no WhatsApp as informações da sua próxima revisão."
	},
	{
		img: require("../assets/lp_card (3).png"),
		alt: "imagem",
		text: "Aproveite para economizar comprando on-line. É fácil e seguro."
	},
	{
		img: require("../assets/lp_card (4).png"),
		alt: "imagem",
		text: "Pode escolher se nós buscamos o seu veículo, ou você o leva até o parceiro."
	},
	{
		img: require("../assets/lp_card (5).png"),
		alt: "imagem",
		text: "Nossa tecnologia instalada nos parceiros gera transparência."
	},
	{
		img: require("../assets/lp_card (6).png"),
		alt: "imagem",
		text: "Registro on-line das revisões e desgastes dos pneus, freios e fluídos."
	}
]
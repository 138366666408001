<template>
	<div id="container">
		<Header />
		<section>
			<div id="formContainer">
				<form @submit.prevent="submit">
					<img src="../assets/dialog.png" alt="Imagem Diálogo" />
					<span id="currentStep">Passo 1 de 3</span>
					<h1>Faça sua cotação sem compromisso!</h1>
					<p>
						<b>É rápido, fácil e gratuito! :)</b>
						<br />Em poucos instantes você descobrirá o quanto pode economizar na sua próxima revisão
					</p>

					<label for="plateInput">
						<b>Placa</b>
						<span>*</span>
					</label>
					<input type="text" class="formInput" v-mask="'AAA-#N##'" id="plateInput" placeholder="Digite a Placa do Seu Veículo" v-model="plate" autocomplete="off" :class="{'uppercase':plate.length>0}" />

					<label for="phoneInput">
						<b>WhatsApp</b>
						<span>*</span>
					</label>
					<input type="text" class="formInput" v-mask="'(##) #####-####'" id="phoneInput" placeholder="Digite o seu WhatsApp com DDD" v-model="phone" autocomplete="off" />

					<button type="submit" class="greenBt">Enviar</button>
				</form>
			</div>
			<div id="imgContainer">
				<img src="../assets/step1img.png" alt="Ilustração Homem sentado no carro" />
			</div>
		</section>
	</div>
</template>

<script>
import Header from '../components/Header'
export default {
	name: "Step1",
	data() {
		return {
			plate: this.$store.state.userInfos.plate,
			phone: this.$store.state.userInfos.phone
		}
	},
	components: {
		Header
	},
	computed: {
		// phoneMask() {
		// 	return this.phone.toString().length < 15 ? '(##) ####-####' : '(##) #####-####'
		// }
	},
	mounted() {
		document.getElementById('plateInput').focus()
	},
	methods: {
		submit() {
			if (this.fieldHasError({plate: this.plate}))
				this.$store.dispatch('showMessage', {error: 'Placa inválida'})
			else if (this.fieldHasError({phone: this.phone}))
				this.$store.dispatch('showMessage', {error: 'Celular inválido'})
			else {
				this.$store.dispatch('setForm1', {
					plate: this.plate,
					phone: this.phone
				})
					.then(() => {
						this.$router.push('/step2')
					})
			}
		}
	}

}
</script>

<style scoped>
#container {
	min-height: 100vh;
	background: url("../assets/pills.png");
	background-size: 100%;
	background-color: #ededed;
}

section {
	width: 100%;
	height: 100%;
	display: flex;
	padding: 50px 0;
}

#formContainer {
	flex: 1;
	display: flex;
	height: 100%;
	/* background-color: red; */
	justify-content: center;
	align-items: center;
}

form {
	width: 90%;
	max-width: 360px;
	background: #f8f8f8;
	padding: 40px 20px;
	box-sizing: border-box;
	border-radius: 0.5rem;
	box-shadow: 0 0 15px #0000004d;
	display: flex;
	flex-direction: column;
	color: #7d7d7d;
}

form img {
	width: 82px;
	margin-bottom: 10px;
}

#currentStep {
	font-size: 14px;
	margin: 0;
}

h1 {
	color: #70b99b;
	margin: 0;
	font-family: arlon-bold;
}

b {
	font-family: arlon-bold;
}

label span {
	color: #70b99b;
	font-family: arlon-bold;
}

button {
	width: 70%;
	margin: 40px auto 0;
}

#imgContainer {
	flex: 1;
	display: none;
	align-items: center;
	justify-content: flex-start;
}

#imgContainer img {
	width: 80%;
	max-width: 862px;
}

@media (min-width: 1024px) {
	#formContainer {
		justify-content: flex-end;
		padding-right: 20px;
	}

	#imgContainer {
		padding-left: 20px;
		display: flex;
	}
}
</style>
<template>
	<div id="container">
		<Header />
		<section>
			<div id="formContainer">
				<form @submit.prevent="submit">
					<img src="../assets/dialog.png" alt="Imagem Diálogo" />
					<span id="currentStep">Passo 3 de 3</span>
					<h1>É um prazer ter você conosco, {{$store.state.userInfos.name}}!</h1>
					<p>
						Para finalizar, nos informe o seu CEP e a quilometragem do seu veículo.
						<br />Assim poderemos analisar a revisão ideal para você!
					</p>

					<label for="postcodeInput">
						<b>CEP</b>
						<span>*</span>
					</label>
					<input type="text" class="formInput" id="postcodeInput" v-mask="'##.###-###'" placeholder="Digite o CEP" v-model="postcode" autocomplete="off" />

					<label for="mileageInput">
						<b>Quilometragem</b>
						<span>*</span>
					</label>
					<input type="text" v-mask="mileageMask" class="formInput" id="mileageInput" placeholder="Digite a quilometragem do seu veículo" v-model="mileage" autocomplete="off" />

					<button type="submit" class="greenBt" :disabled="loading">Solicitar cotação!</button>
				</form>
			</div>
			<div id="imgContainer">
				<img src="../assets/step3img.png" alt="Ilustração Homem acenando" />
			</div>
		</section>
		<transition name="fade">
			<div id="loading" v-if="loading">
				<img src="../assets/loading.gif" alt="Loading">
			</div>
		</transition>
	</div>
</template>

<script>
import Header from '../components/Header'
import axios from 'axios'
export default {
	name: "Step3",
	data() {
		return {
			postcode: this.$store.state.userInfos.postcode,
			mileage: this.$store.state.userInfos.mileage,
			loading: false
		}
	},
	components: {
		Header
	},
	mounted() {
		document.getElementById('postcodeInput').focus()
	},
	computed: {
		mileageMask() {
			if (this.mileage) {
				let mileageLength = this.mileage.toString().length

				if (mileageLength <= 5)
					return '#.###'
				else if (mileageLength <= 6)
					return '##.###'
				else
					return '###.###'
			}
			else
				return ''
		}
	},
	methods: {
		submit() {
			if (this.fieldHasError({postcode: this.postcode}))
				this.$store.dispatch('showMessage', {error: 'CEP inválido'})
			else if (this.fieldHasError({mileage: this.mileage}))
				this.$store.dispatch('showMessage', {error: 'Quilometragem inválida'})
			else {
				this.loading = true
				this.$store.dispatch('setForm3', {
					postcode: this.postcode,
					mileage: this.mileage
				})
					.then(() => {
						axios.post(`${ this.$store.state.baseURL }review_quotation/register`, {
							name: this.$store.state.userInfos.name,
							email: this.$store.state.userInfos.email,
							cell_phone: this.$store.state.userInfos.phone,
							postcode: this.$store.state.userInfos.postcode,
							plate: this.$store.state.userInfos.plate.toUpperCase(),
							mileage: this.$store.state.userInfos.mileage,
							origin_url: location.href,
							isNewLp: true
						})
							.then((res) => {
								location.href = res.data.link_wpp
							})
							.catch((err) => {
								this.loading = false
								console.log(err)
								if (err == 'Error: Network Error')
									this.$store.dispatch('showMessage', {error: 'Houve uma falha na conexão com o servidor... Por favor tente novamente mais tarde.'})
								else {
									this.$store.dispatch('buildMessage', err.response.data)
								}
							})
					})
			}
		}
	}

}
</script>

<style scoped>
#container {
	min-height: 100vh;
	background: url("../assets/pills.png");
	background-size: 100%;
	background-color: #ededed;
}

section {
	width: 100%;
	height: 100%;
	display: flex;
	padding: 50px 0;
}

#formContainer {
	flex: 1;
	display: flex;
	height: 100%;
	/* background-color: red; */
	justify-content: center;
	align-items: center;
}

form {
	width: 90%;
	max-width: 360px;
	background: #f8f8f8;
	padding: 40px 20px;
	box-sizing: border-box;
	border-radius: 0.5rem;
	box-shadow: 0 0 15px #0000004d;
	display: flex;
	flex-direction: column;
	color: #7d7d7d;
}

form img {
	width: 82px;
	margin-bottom: 10px;
}

#currentStep {
	font-size: 14px;
	margin: 0;
}

h1 {
	color: #70b99b;
	margin: 0;
	font-family: arlon-bold;
}

b {
	font-family: arlon-bold;
}

label span {
	color: #70b99b;
	font-family: arlon-bold;
}

button {
	width: 70%;
	margin: 40px auto 0;
}

#imgContainer {
	flex: 1;
	display: none;
	align-items: center;
	justify-content: flex-start;
}

#imgContainer img {
	width: 80%;
	max-width: 374px;
}

#loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #612c7d;
}

#loading img {
	border-radius: .5rem;
}

@media (min-width: 1024px) {
	#formContainer {
		justify-content: flex-end;
		padding-right: 20px;
	}

	#imgContainer {
		padding-left: 20px;
		display: flex;
	}
}

.fade-enter-active {
	animation: fade .2s linear 1;
}

.fade-leave-active {
	animation: fade .2s linear 1 reverse;
}

@keyframes fade {
	from{
		opacity:0;
	}
	to{
		opacity:1;
	}
}
</style>
<template>
	<div id="container">
		<header>
			<div id="headerContainer">
				<div id="headerPills"></div>
				<div id="headerInnerContainer">
					<div id="headerInnerContainer2">
						<div id="aboutPremaDiv">
							<img src="../assets/logo 1.png" alt="Logo" />
							<h1>
								Economize $$$ comparando os preços com as
								<br />concessionárias!
							</h1>
							<p id="aboutPremaP">
								Somos a empresa mais inovadora e especializada em revisões programadas pelo fabricante do carro. Aquela feita nos 30 mil, 40 mil, 50 mil, 60 mil, ...km (quilômetros).
								<br />Nascemos para resolver um problema crônico de falta de credibilidade e insegurança na manutenção preventiva dos veículos.
							</p>
						</div>
						<div id="formDiv">
							<!-- ADICIONAR AQUI O MENU DESKTOP -->
							<form @submit.prevent="submit">
								<h2>Faça sua cotação sem compromisso</h2>
								<p>
									<b>É rápido, fácil e gratuito! :)</b>
									<br />Em poucos instantes você descobrirá o quanto pode economizar na sua próxima revisão
								</p>
								<label for="plateInput">
									<b>Placa</b>
									<span>*</span>
									<br />
								</label>
								<input type="text" v-mask="'AAA-#N##'" class="formInput" placeholder="Digite a Placa do Seu Veículo" v-model="plate" :class="{'uppercase':plate.length>0}" />
								<label for="phoneInput">
									<b>WhatsApp</b>
									<span>*</span>
									<br />
								</label>
								<input type="text" v-mask="'(##) #####-####'" class="formInput" placeholder="Digite o seu WhatsApp com DDD" v-model="phone" />
								<button type="submit" class="greenBt">Solicitar Cotação</button>
							</form>
						</div>
					</div>
				</div>
				<div id="headerFooter"></div>
			</div>
			<div id="cardsDiv">
				<ul id="cardsList">
					<li v-for="(card, index) in cards" :key="'card'+index">
						<img :src="card.img" :alt="card.alt" />
						<p>{{card.text}}</p>
						<span>{{index+1}}</span>
					</li>
				</ul>
			</div>
		</header>
		<div id="wearDiv">
			<div class="innerWearDiv">
				<img src="../assets/vida-util (01).png" alt="Gráfico de Vida Útil 1" />
				<img src="../assets/vida-util (02).png" alt="Gráfico de Vida Útil 2" />
			</div>
			<div class="innerWearDiv">
				<div>
					<h1>Confira o gráfico com a vida útil dos pneus, freios e fluídos.</h1>
					<p>
						Só na Prema, você consegue visualizar o desgaste dos principais itens de segurança.
						<br />Mensuramos, com ferramentas especiais, os pneus, freios, principais fluídos e palhetas para não ter surpresa quando precisar trocar.
					</p>
				</div>
			</div>
		</div>
		<!-- <div id="cta1"> -->
		<div id="cta1Container">
			<picture>
				<source srcset="../assets/layer1-mobile.png" media="(max-width: 799px)" />
				<img src="../assets/layer1-desktop.png" alt="Prancheta" />
			</picture>
			<div>
				<b>Descubra o que trocar na sua próxima revisão</b>
				<button class="purpleBt" @click="$router.push('/step1')">Solicitar Cotação</button>
			</div>
		</div>
		<!-- </div> -->
		<div id="stickersDiv">
			<div class="sticker">
				<img src="../assets/camera.png" alt="Câmera" />
				<b>Transparência com nossa tecnologia IoT</b>
				<span>A Prema filma para você ficar Seguro.</span>
			</div>
			<div class="sticker">
				<img src="../assets/oil.png" alt="Óleo" style="filter: drop-shadow(0 0 1px black)" />
				<b>Peças Recomendadas</b>
				<span>Lubrificantes (óleo) e peças específicas para seu carro com garantia de 1 ano.</span>
			</div>
			<div class="sticker">
				<img src="../assets/lp_card (1).png" alt="Segurança" />
				<b>Dados on-line</b>
				<span>Seu carro valoriza na hora da troca com histórico das revisões, conforme a indicação do fabricante do seu carro.</span>
			</div>
			<div class="sticker">
				<img src="../assets/elevator.png" alt="Oficina" />
				<b>Parceiros Credenciados</b>
				<span>A revisão é realizada no padrão Prema. Conforme indicação do fabricante. Nossos parceiros foram treinados para isso.</span>
			</div>
		</div>
		<div id="testmonialsOuterDiv">
			<button class="testmonialBt" id="leftTestmonialBt" @click="showTestmonial(Number(showingTestmonial)-1)">
				<img src="../assets/left arrow.png" alt="Selecionar depoimento anterior" />
			</button>
			<div id="testmonialsDiv">
				<div id="testmonialsBg"></div>
				<div id="testmonialsContainer">
					<div class="testmonialDiv" v-for="(testmonial, index) in testmonials" :key="'testmonial'+index" :id="'testmonial'+index">
						<img :src="testmonial.img" :alt="testmonial.alt" />
						<b>{{testmonial.name}}</b>
						<em>{{testmonial.job}}</em>
						<small>{{testmonial.address}}</small>
						<p>{{testmonial.testmonial}}</p>
					</div>
				</div>
			</div>
			<button class="testmonialBt" id="rightTestmonialBt" @click="showTestmonial(Number(showingTestmonial)+1)">
				<img src="../assets/right arrow.png" alt="Selecionar próximo depoimento" />
			</button>
		</div>
		<div id="cta2">
			<div id="cta2Container">
				<div>
					<b>Pessoas usando tecnologia para gerar transparência e economizar. Confira!</b>
					<button class="purpleBt" @click="$router.push('/step1')">Compare Preços</button>
				</div>
				<picture>
					<source srcset="../assets/layer2-mobile.png" media="(max-width: 799px)" />
					<img src="../assets/layer2-desktop.png" alt="Mecânico" />
				</picture>
			</div>
		</div>
		<!-- <footer>
			<div id="footerContainer">
				<img src="../assets/logo 2.png" alt="Logo" id="footerLogo" />
				<div id="footerInnerContainer">
					<div class="footerDiv">
						<b>Dúvidas? Entre em contato!</b>
						<p>Conheça o melhor da manutenção preventiva para automóveis. Aprenda como economizar e aumentar o valor de revenda do seu carro. Tudo com muita transparência e confiança!</p>
						<a href="https://www.premacar.com.br/ajuda" target="_blank" class="purpleBt">Acessar FAQ</a>
						<a href="https://api.whatsapp.com/send?phone=+555182826000" target="_blank" class="purpleBt">Falar com um consultor Prema</a>
					</div>
					<div class="footerDiv">
						<a href="https://www.google.com/maps/place/Av.+Sert%C3%B3rio,+910+-+Navegantes,+Porto+Alegre+-+RS,+91020-000/@-30.0003304,-51.1965452,20z/data=!4m5!3m4!1s0x951979f20afc7f5f:0xd99b278a96d9e72b!8m2!3d-30.000334!4d-51.196319" target="_blank" class="footerLink">
							<img src="../assets/pin.png" alt="Marcador" />
							<span>Temos parceiros credenciados para realizar sua próxima revisão nas cidades de Porto Alegre - RS e Curitiba - PR</span>
						</a>
						<a href="tel://.+555131811642" class="footerLink">
							<img src="../assets/phone.png" alt="Telefone" />
							<span>(51) 3181-1642</span>
						</a>
						<a href="mailto://oi@premacar.com.br" class="footerLink">
							<img src="../assets/envelope.png" alt="Email" />
							<span>oi@premacar.com.br</span>
						</a>
					</div>
				</div>
			</div>
		</footer>-->
	</div>
</template>

<script>
export default {
	name: 'Home',
	data() {
		return {
			plate: this.$store.state.userInfos.plate,
			phone: this.$store.state.userInfos.phone,
			cards: this.$store.state.cards,
			testmonials: this.$store.state.testmonials,
			showingTestmonial: 0,
			testmonialTimer: null
		}
	},
	computed: {
		getShowingTestmonial() {			 //Usada dentro de timouts para retornar
			return this.showingTestmonial//o valor atualizado do showingTestmonial
		}
		// phoneMask() {
		// 	return this.phone.toString().length < 15 ? '(##) ####-####' : '(##) #####-####'
		// }
	},
	beforeMount() {
		window.onresize = () => {
			this.resize()
		}
	},
	mounted() {
		this.showTestmonial(0)
	},
	methods: {
		submit() {
			if (this.fieldHasError({plate: this.plate}))
				this.$store.dispatch('showMessage', {error: 'Placa inválida'})
			else if (this.fieldHasError({phone: this.phone}))
				this.$store.dispatch('showMessage', {error: 'Celular inválido'})
			else {
				this.$store.dispatch('setForm1', {
					plate: this.plate,
					phone: this.phone
				})
					.then(() => {
						this.$router.push('/step2')
					})
			}
		},
		resize() {
			clearTimeout(this.testmonialTimer)
			let list = document.getElementById('testmonialsDiv')
			let testmonialsContainer = document.getElementById('testmonialsContainer')
			let testmonials = Array.from(testmonialsContainer.querySelectorAll('.testmonialDiv'))

			if (testmonials[this.showingTestmonial]) {
				list.scrollTo({left: testmonials[this.showingTestmonial].offsetLeft - (window.innerWidth / 2) + testmonials[this.showingTestmonial].offsetWidth / 2})

				this.testmonialTimer = setTimeout(() => {
					this.showTestmonial(this.getShowingTestmonial + 1)
				}, 10000)
			}
		},
		showTestmonial(index) {
			clearTimeout(this.testmonialTimer)
			let list = document.getElementById('testmonialsDiv')
			let testmonialsContainer = document.getElementById('testmonialsContainer')
			let testmonials = Array.from(testmonialsContainer.querySelectorAll('.testmonialDiv'))

			if (list.scrollWidth > list.offsetWidth) {
				if (index < 0) {
					list.scrollTo({left: testmonials[1].offsetLeft - (window.innerWidth / 2) + testmonials[1].offsetWidth / 2})
					testmonialsContainer.insertBefore(testmonials[testmonials.length - 1], testmonials[0])
					index = 0
				}
				else if (index > this.testmonials.length - 1) {
					list.scrollTo({left: testmonials[testmonials.length - 2].offsetLeft - (window.innerWidth / 2) + testmonials[testmonials.length - 2].offsetWidth / 2})
					testmonialsContainer.appendChild(testmonials[0])
					index = testmonials.length - 1
				}
			}

			testmonials = Array.from(testmonialsContainer.querySelectorAll('.testmonialDiv'))

			if (this.showingTestmonial != index)
				this.showingTestmonial = index

			if (testmonials[index]) {
				list.scrollTo({left: testmonials[index].offsetLeft - (window.innerWidth / 2) + testmonials[index].offsetWidth / 2, behavior: 'smooth'})
				this.testmonialTimer = setTimeout(() => {
					this.showTestmonial(this.getShowingTestmonial + 1)
				}, 10000)
			}
		}
	},
	beforeRouteLeave(to, from, next) {
		clearTimeout(this.testmonialTimer)
		next()
	}
}
</script>

<style scoped>
#container {
	background: #fff;
	width: 100%;
	overflow: hidden;
}

header {
	position: relative;
	color: #fff;
}

#headerPills {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url("../assets/pills.png");
	background-size: 200% 150%;
	animation: 120s linear bgAnim infinite;
	z-index: 1;
}

#headerContainer {
	background-image: url("../assets/background-mobile.png");
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: 50% 40%;
}

#headerInnerContainer {
	position: relative;
	background: linear-gradient(to bottom, #2c0b5799, #2c0b57cc 50%);
	/* background: #2c0b57cc; */
	z-index: 2;
	padding: 0;
}

#headerInnerContainer2 {
	max-width: 980px;
	text-align: center;
	padding: 60px 2% 160px;
	box-sizing: border-box;
	margin: 0 auto;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
	gap: 10px;
}

#aboutPremaDiv {
	flex: 1;
	padding: 0;
	box-sizing: border-box;
}

#aboutPremaDiv h1 {
	font-size: 40px;
	margin: 60px auto 30px;
	font-family: arlon-bold;
}

#aboutPremaP {
	margin: 0 0 40px;
}

#formDiv {
	flex: 1;
}

header form {
	background: #f9f9f9;
	color: #7d7d7d;
	width: 100%;
	max-width: 330px;
	min-width: 260px;
	margin: 0 auto;
	border-radius: 0.8rem;
	padding: 20px 14px;
	box-sizing: border-box;
	text-align: left;
}

header form h2 {
	font-family: arlon-bold;
	color: #612c7d;
	font-size: 24px;
	margin: 15px 0;
}

header form b {
	font-family: arlon-bold;
}

header form p {
	font-size: 16px;
	line-height: 18px;
}

header label span {
	color: #70b99b;
}

header form button {
	margin: 20px auto 10px;
}

#headerFooter {
	background-color: #f3f3f3;
	margin: 0;
	padding: 0;
	height: 180px;
	width: 100%;
}

#cardsDiv {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: bottom;
}

#cardsList {
	list-style: none;
	margin: 10px auto;
	padding: 20px 0 0;
	overflow-x: auto;
	overflow-y: visible;
	display: flex;
}

#cardsList li {
	margin: 0 12px;
	background: #ededed;
	border: 2px solid #7d7d7d33;
	color: #000;
	font-size: 14px;
	font-weight: bolder;
	border-radius: 1rem;
	padding: 4px 8px;
	min-width: 100px;
	max-width: 100px;
	text-align: center;
	z-index: 3;
	position: relative;
	overflow: hidden;
	user-select: none;
	transition: 0.2s;
}

#cardsList li:hover {
	transform: translateY(-20px);
}

#cardsList li img {
	width: 84px;
	margin: 0;
	padding: 10px;
	box-sizing: border-box;
	background: #bdbdbd33;
	border-radius: 0.5rem;
}

#cardsList li span {
	position: absolute;
	left: -12px;
	bottom: -18px;
	font-family: arlon-bold;
	font-size: 140px;
	line-height: 140px;
	color: #7d7d7d60;
}

#wearDiv {
	width: 100%;
	padding: 30px 10px;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.innerWearDiv:nth-of-type(1) {
	flex: 1;
	max-width: 80%;
	z-index: 2;
	margin: 15px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
}

.innerWearDiv:nth-of-type(1) img {
	/* width: 90%; */
	width: 320px;
	z-index: 2;
}

.innerWearDiv:nth-of-type(2) {
	flex: 1;
	min-width: 320px;
	margin: -60px auto 0;
}

.innerWearDiv:nth-of-type(2) div {
	background: #f8f8f8;
	text-align: center;
	padding: 40px 20px 70px;
	box-sizing: border-box;
	border-radius: 0.3rem;
}

.innerWearDiv:nth-of-type(2) h1 {
	color: #612c7d;
	font-size: 24px;
	font-family: arlon-bold;
	margin: 30px 20px;
}

/* #cta1 {
	position: relative;
	background: linear-gradient(to bottom, #fff 5%, #f8c161 5%);
} */

#cta1Container {
	position: relative;
	margin: auto;
	/* max-width: 980px; */
	background: linear-gradient(to bottom, #fff 5%, #f8c161 5%);
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

#cta1Container img {
	margin-bottom: -4px;
}

#cta1Container div {
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	text-align: right;
	gap: 10px;
	font-family: arlon-bold;
	color: #fff;
}

#cta1Container div b {
	font-size: 30px;
	/* margin: 10px 0; */
}

#cta1Container div button {
	/* margin: 10px 0; */
	border: 1px solid #bdbdbd;
	white-space: nowrap;
}

#stickersDiv {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 40px 0;
}

.sticker {
	width: 200px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	margin: 5px;
	text-align: center;
	color: #7d7d7d;
}

.sticker img {
	margin: auto;
	width: 64px;
}

.sticker b {
	font-family: arlon-bold;
	margin: 14px 0 6px;
}

#testmonialsOuterDiv {
	position: relative;
}

#testmonialsDiv {
	box-shadow: 0 -1px 2px #bdbdbd4d;
	width: 100%;
	height: 620px;
	overflow: hidden;
	position: relative;
}

#testmonialsContainer {
	/* background: url("../assets/pills.png");
	background-position: bottom center;
	background-size: 60%; */
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
	width: 400%;
	padding: 100px 0;
}

#testmonialsBg {
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url("../assets/pills.png");
	background-size: 200% 150%;
	animation: 120s linear bgAnim infinite;
}

@keyframes bgAnim {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 400% 400%;
	}
}

@media (min-width: 640px) {
	#headerPills,
	#testmonialsBg {
		background-size: 80% 80%;
		animation: 60s linear bgAnim infinite;
	}
	@keyframes bgAnim {
		from {
			background-position: 400% 300%;
		}
		to {
			background-position: 0 0;
		}
	}
}

.testmonialDiv {
	background: #612c7d;
	width: 200px;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	text-align: center;
	color: #fff;
	padding: 12px;
	box-sizing: content-box;
	z-index: 1;
}

.testmonialDiv img {
	width: 140px;
	display: block;
	margin: -70px auto 20px;
}

.testmonialBt {
	align-self: center;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	position: absolute;
	top: 0;
	height: 100%;
	background: transparent;
}

.testmonialBt:hover,
.testmonialBt:focus,
.testmonialBt:active {
	transform: none !important;
	box-shadow: none !important;
}

#leftTestmonialBt {
	left: 0;
}

#rightTestmonialBt {
	right: 0;
}

#cta2 {
	position: relative;
	background: linear-gradient(to bottom, transparent 11%, #f8c161 11%);
}

#cta2Container {
	position: relative;
	margin: -33px auto 0;
	max-width: 980px;
	height: 300px;
}

#cta2Container picture {
	position: absolute;
	right: 0;
	bottom: 0;
	margin-bottom: -4px;
}

#cta2Container div {
	position: absolute;
	top: 11%;
	left: 0;
	width: calc(100% - 180px);
	height: 89%;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	text-align: left;
	font-family: arlon-bold;
	color: #fff;
	font-size: 28px;
	line-height: 28px;
	margin: 0;
	z-index: 2;
}

#cta2Container div b {
	margin: 0;
}

#cta2Container div button {
	margin: 10px 0;
	border: 1px solid #bdbdbd;
	white-space: nowrap;
}

#footerContainer {
	width: 100%;
	max-width: 1280px;
	margin: auto;
}

#footerLogo {
	margin: 20px;
}

#footerInnerContainer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.footerDiv {
	margin: 20px;
	flex: 1;
	min-width: 260px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.footerDiv .purpleBt {
	margin: 5px 0;
	padding: 8px 12px;
	box-sizing: border-box;
	border-radius: 0.4rem;
	text-decoration: none;
	font-family: arlon-bold;
}

.footerLink {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 14px;
	color: #7d7d7d;
	margin: 10px 0;
	width: fit-content;
	text-decoration: none;
}

.footerLink img {
	margin-right: 10px;
}

@media (min-width: 409px) {
	#cta1Container {
		background: linear-gradient(to bottom, transparent 22%, #f8c161 22%);
	}
}

@media (min-width: 451px) {
	#cta1Container {
		background: linear-gradient(to bottom, transparent 32%, #f8c161 32%);
	}
}

@media (min-width: 499px) {
	#aboutPremaDiv,
	#formDiv {
		min-width: 300px;
	}
}

@media (min-width: 550px) {
	#cta1Container {
		background: linear-gradient(to bottom, transparent 45%, #f8c161 45%);
	}
}

@media (min-width: 653px) {
	#aboutPremaDiv {
		text-align: left !important;
	}

	#aboutPremaDiv img {
		display: block;
		left: 0;
	}

	#aboutPremaDiv h1 {
		margin-left: 0;
	}
}

@media (min-width: 660px) {
	.innerWearDiv:nth-of-type(1) img {
		width: 400px;
	}

	.innerWearDiv:nth-of-type(2) {
		margin: 0 10px;
	}
}

@media (min-width: 690px) {
	#aboutPremaDiv {
		margin-right: 10px;
	}

	#formDiv {
		margin-left: 10px;
	}
}

@media (min-width: 760px) {
	.innerWearDiv:nth-of-type(1) {
		align-items: flex-end;
	}

	.innerWearDiv:nth-of-type(2) {
		align-items: flex-start;
	}
	.innerWearDiv:nth-of-type(2) div {
		max-width: 400px;
	}
}

@media (min-width: 800px) {
	#headerContainer {
		background-image: url("../assets/background.png");
		background-attachment: fixed;
		background-position: 80% 50%;
	}

	/* #cta1 {
		margin-top: -60px;
		z-index: 2;
		background: linear-gradient(to bottom, transparent 44%, #f8c161 44%);
	} */

	#cta1Container div {
		margin-bottom: 20px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	#wearDiv div:nth-of-type(1) {
		margin: 0 15px;
	}

	#cta2Container picture {
		left: 0;
	}

	#cta2Container div {
		left: unset;
		right: 1px !important;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		text-align: right;
	}

	#cta2Container div b {
		margin: 0 15px;
	}
}

@media (min-width: 1024px) {
	#testmonialsContainer {
		width: 100%;
	}

	.testmonialBt {
		display: none;
	}
}

@media (min-width: 1047px) {
	#cta1Container div {
		margin-bottom: 40px;
	}
}

@media (min-width: 1280px) {
	#headerContainer {
		background-size: 150%;
		background-position: 100% 50%;
	}
}
</style>
